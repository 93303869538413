import { Breadcrumb, BreadcrumbItem } from '@/components/Breadcrumb'
import { SEO } from '@/components/SEO'
import { PortfolioProjects } from '@/sections/PortfolioProjects'
import { ProjectsPageQuery } from '@/types/__generated__/ProjectsPageQuery'
import { graphql } from 'gatsby'
import React from 'react'

export const query = graphql`
  query ProjectsPageQuery {
    contentfulPagePortfolioProjects {
      title
      metaDescription
    }
  }
`

type Props = {
  data: ProjectsPageQuery
}

const ProjectsPage: React.FC<Props> = ({ data }) => {
  const { title, metaDescription } = data.contentfulPagePortfolioProjects

  return (
    <>
      <SEO title={title} metaDescription={metaDescription} />

      <Breadcrumb>
        <BreadcrumbItem href="/">Home</BreadcrumbItem>
        <BreadcrumbItem href="/portfolio/">Portfolio</BreadcrumbItem>
        <BreadcrumbItem>{title}</BreadcrumbItem>
      </Breadcrumb>

      <PortfolioProjects id="portfolio-projects" />
    </>
  )
}

export default ProjectsPage
